<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedAnamneses"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <router-link
          :to="{name: 'add_anamnese'}"
          class="btn btn-outline-primary btn-icon"
          v-if="canAdd"
        >
          <i class="icofont icofont-plus" />
        </router-link>
      </template>
    </data-table>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'
import moment from 'moment'
import DataTable from '../../../components/dataTable/local.vue'
import navbar from '../../../components/navbar.vue'
import { TEXT_TYPE, COMPONENT_TYPE, DATE_TYPE} from '../../../components/dataTable/dataType'
const Animal = () => import('../../../components/sanitaire/animal.vue')
const CreateBy = () => import('../../../components/sanitaire/createdBy.vue')
const Motif = () => import('../../../components/sanitaire/anamnese/motif.vue')
const Actions = () => import('../../../components/sanitaire/anamnese/actions.vue')
export default {
    components: {navbar,  DataTable },
    data(){
        return {
          navbarItems: [
                {
                    libelle: 'Sanitaire'
                },
                {
                    libelle: 'Anamnese',
                }
                ],
                pageIcon: 'la-hospital',
                pageTitle: 'Anamnese',
                pageDescription: 'List des anamneses', 
            nom: null,
            libelle: null,
            description: null,
            model: null
        }
    },
    methods: {
        ...mapMutations({
            done: 'DONE'
        })
    },
    computed: {
        ...mapGetters({
            anamneses: 'sanitaire/anamneses',
            is_super_admin: 'auth/is_super_admin',
            is_veterinaire: 'auth/is_veterinaire',
            is_assistant_veto: 'auth/is_assistant_veterinaire',
            is_assistant_veto_major: 'auth/is_assistant_veto_major'
        }),
        sortedAnamneses(){
            return [...this.anamneses].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
        },
        
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                {label: 'Motif', name: 'motif', type: COMPONENT_TYPE, component: Motif},
                {label: 'Date consultation', name: 'operationDate', type: DATE_TYPE},
                {label: 'Createur', name: 'createby', type: COMPONENT_TYPE, component: CreateBy},
                {label: 'Date création', name: 'createdAt', type: DATE_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions, style: {width: "15%"}}
            ]
        },
        canAdd(){
          return this.is_super_admin || this.is_veterinaire || this.is_assistant_veto || this.is_assistant_veto_major
        }
    }
}
</script>